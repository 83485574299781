@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  /* #region  /**=========== Primary Color =========== */
  
  --tw-color-primary-50: 255 255 255; /* #ffffff */
  --tw-color-primary-100: 208 210 211;  /* #d0d2d3 */
  --tw-color-primary-200: 109 110 112; /* #6d6e70 */
  --tw-color-primary-300: 60 0 114; /* #3c0072 dark purple */
  --tw-color-primary-400: 72 193 169; /* #48c1a9 */
  --tw-color-primary-500: 148 94 224; /* #945ee0 light purple */
  --tw-color-primary-600: 100 22 210; /* #6416d2 medium purple */
  --tw-color-primary-700: 45 197 105;
  --tw-color-primary-800: 46 224 86;
  --tw-color-primary-900: 48 252 68;
  --color-primary-50: rgb(var(--tw-color-primary-50)); 
  --color-primary-100: rgb(var(--tw-color-primary-100));
  --color-primary-200: rgb(var(--tw-color-primary-200));
  --color-primary-300: rgb(var(--tw-color-primary-300)); 
  --color-primary-400: rgb(var(--tw-color-primary-400)); 
  --color-primary-500: rgb(var(--tw-color-primary-500)); 
  --color-primary-600: rgb(var(--tw-color-primary-600)); 
  --color-primary-700: rgb(var(--tw-color-primary-700)); 
  --color-primary-800: rgb(var(--tw-color-primary-800)); 
  --color-primary-900: rgb(var(--tw-color-primary-900)); 
  
  /* #endregion  /**======== Primary Color =========== */
}

:root[className~="dark"] {
  --tw-color-primary-50: 255 255 255; /* #ffffff */
  --tw-color-primary-100: 208 210 211;  /* #d0d2d3 */
  --tw-color-primary-200: 109 110 112; /* #6d6e70 */
  --tw-color-primary-300: 60 0 114; /* #3c0072 dark purple */
  --tw-color-primary-400: 72 193 169; /* #48c1a9 */
  --tw-color-primary-500: 148 94 224; /* #945ee0 light purple */
  --tw-color-primary-600: 100 22 210; /* #6416d2 medium purple */
  --tw-color-primary-700: 45 197 105;
  --tw-color-primary-800: 46 224 86;
  --tw-color-primary-900: 48 252 68;
  --color-primary: 247 147 34;
  --color-text: 33 33 33;
  --color-success: 0 200 81;
  --color-info: 51 181 229;
  --color-warn: 255 187 51;
  --color-error: 254 78 78;
}

@layer base {

 
  @font-face {
    font-family: 'F37GingerSoft-Regular';
    src: url("../../public/fonts/F37GingerSoft-Regular.otf") format("otf");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  } 

  @font-face {
    font-family: "Adieu-Regular";
    src: local("Adieu-Regular"),
        url("../../public/fonts/Adieu-Regular.otf") format("otf");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  } 

  @font-face {
    font-family: "Agbalumo";
    src: 
    local("Agbalumo"),
    url("https://fonts.googleapis.com/css2family=Agbalumo&display=swap");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  }

  @font-face {
    font-family: "Lato";
    src: 
    local("Lato"),
    url("https://fonts.googleapis.com/css2family=Lato&display=swap");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  }

  @font-face {
    font-family:"Sofia-Pro";
    src:  local("Sofia Pro"),
          local("Sofia-Pro"),
          local("SofiaPro"),
          url("../../public/fonts/SofiaPro-Regular.woff2") format("woff2");
    font-style: normal;
    font-weight: 500;
    font-display: swap;
  } 
 

  .cursor-newtab {
    cursor: url('/images/new-tab.png') 10 10, pointer;
  }

  /* #region  /**=========== Typography =========== */
  .h0 {
    @apply text-3xl font-bold md:text-5xl;
  }

  h1,
  .h1 {
    @apply text-2xl font-bold md:text-4xl;
  }

  h2,
  .h2 {
    @apply text-xl font-bold md:text-3xl;
  }

  h3,
  .h3 {
    @apply text-lg font-bold md:text-2xl;
  }

  h4,
  .h4 {
    @apply text-base font-bold md:text-lg;
  }

  body,
  .p {
    @apply text-sm md:text-base;
  }
  .bg-dark {
    @apply bg-gray-800 text-gray-100;
  }
  /* #endregion  /**======== Typography =========== */

  .layout {
    /* 1100px */
    max-width: 68.75rem;
    @apply mx-auto w-11/12;
  }

  .bg-dark a.custom-link {
    @apply border-gray-200 hover:border-gray-200/0;
  }

  /* Class to adjust with sticky footer */
  .min-h-main {
    @apply min-h-[calc(100vh-56px)];
  }
}

@layer utilities {
  .animated-underline {
    background-image: linear-gradient(#33333300, #33333300),
      linear-gradient(
        to right,
        var(--color-primary-400),
        var(--color-primary-500)
      );
    background-size: 100% 2px, 0 2px;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
  }
  @media (prefers-reduced-motion: no-preference) {
    .animated-underline {
      transition: 0.3s ease;
      transition-property: background-size, color, background-color,
        border-color;
    }
  }
  .animated-underline:hover,
  .animated-underline:focus-visible {
    background-size: 0 2px, 100% 2px;
  }
}

.dm-mono-light {
  font-family: "DM Mono", monospace;
  font-weight: 300;
  font-style: normal;
}

.dm-mono-regular {
  font-family: "DM Mono", monospace;
  font-weight: 400;
  font-style: normal;
}

.dm-mono-medium {
  font-family: "DM Mono", monospace;
  font-weight: 500;
  font-style: normal;
}

.dm-mono-light-italic {
  font-family: "DM Mono", monospace;
  font-weight: 300;
  font-style: italic;
}

.dm-mono-regular-italic {
  font-family: "DM Mono", monospace;
  font-weight: 400;
  font-style: italic;
}

.dm-mono-medium-italic {
  font-family: "DM Mono", monospace;
  font-weight: 500;
  font-style: italic;
}
